'use strict'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
//import ImgLoader from './jquery.imgloader.min.js'

(function($) {

  viewportUnitsBuggyfill.init()

  if($('#index-animate').length){
    window.onload = function () {
      $('#index-animate').addClass('hidden')
    }
  }

  if($('#index-swiper2').length){
    var indexSwiper2 = new Swiper('#index-swiper2', {
      effect: 'fade',
      loop: true,
      speed: 1000,
    })
  }

  if($('.slider').length){

    const bx = $('.slider').bxSlider({
      mode: 'fade',
      speed: 1000,
      pause: 3000,
      auto: true,
      pager: false,
      controls: false,
      touchEnabled: false,
      onSlideBefore: function($slideElement, oldIndex, newIndex){
        indexSwiper2.slideTo(newIndex+1)
      }
    })

    console.log(bx)
  }

  if($('#index-swiper3').length){
    const indexSwiper3 = new Swiper('#index-swiper3', {
      loop: true,
      slidesPerView: '4',
      centeredSlides: true,
      spaceBetween: 30,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        580: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        680: {
          slidesPerView: 4,
          spaceBetween: 30
        }
      }
    })
  }

  if($('#works-swiper').length){
    const worksSwiper = new Swiper('#works-swiper', {
      centeredSlides: true,
      loop: true,
      slidesPerView: '1',
    })

    worksSwiper.on('slideChange',function(){
      let active = worksSwiper.activeIndex
      let list = $('.c-wrap > div')
      // list.removeClass('active')
      // $(list[active]).addClass('active')
    })

    $('.c-wrap > div').on('click',(e)=>{
      $('.c-wrap > div').removeClass('active')
      $(e.currentTarget).addClass('active')
      $(e.currentTarget).index()
      worksSwiper.slideTo($(e.currentTarget).index()+1)
    })

  }

  if($('#work-swiper').length){
    var workSwiper = new Swiper('#work-swiper', {
      centeredSlides: true,
      loop: true,
      slidesPerView: '1',
      speed: 600,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    $('.thums .thum').on('click touchstart',(e)=>{
      console.log('koko')
      $('.thums .thum .waku').removeClass('active')
      let waku = $(e.currentTarget).find('.waku')
      waku.addClass('active')
      let nextswiper = $(e.currentTarget).index()+1
      let count = $('.thums .thum').length
      workSwiper.slideTo(nextswiper)
    })

  }

  if($('#project-swiper').length){
    const projectSwiper = new Swiper('#project-swiper', {
      effect: 'fade',
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
      },
    })
  }

  $('.hamburger').on('click', (e) => {
    $('.hamburger').toggleClass('is-active')
    $('.mobile-nav').toggleClass('open')
  })

  $('#page-philosophy .yomu').on('click',function(e){
    e.preventDefault()
    $('.story').slideDown('slow')
  })

  $('#page-philosophy .close-btn').on('click',function(e){
    e.preventDefault()
    $('.story').slideUp('slow')
  })

})(jQuery);